import React from 'react';
import PropTypes from 'prop-types';

const Indicator = ({ active }) => {
  return (
    <span className={`step bg-white rounded-pill ${active ? 'active' : ''}`}></span>
  )
};

Indicator.propTypes = {
  active: PropTypes.bool,
}

Indicator.defaultProps = {
  active: false,
}

export default Indicator;
