import {
  PARAM_SANDY,
  PARAM_ROCKY,
  PARAM_NORTH,
  PARAM_EAST,
  PARAM_SOUTH,
  PARAM_WEST,
  PARAM_BUS,
  PARAM_NUDIST,
  PARAM_WILD
} from '../consts/params';
import { TYPE_OBLIGATORY, TYPE_OBLIGATORY_REVERSED, TYPE_OPTIONAL } from '../consts/questionTypes';
import {
  Q1_ANSWER_SAND,
  Q1_ANSWER_ROCKY,
  Q2_ANSWER_NORTH,
  Q2_ANSWER_EAST,
  Q2_ANSWER_SOUTH,
  Q2_ANSWER_WEST,
  Q3_ANSWER_BUS,
  Q3_ANSWER_CAR,
  Q4_ANSWER_NUDITY,
  Q4_ANSWER_BIKINI,
  Q6_ANSWER_WILD,
  Q6_ANSWER_NORMAL,
  Q7_ANSWER_SWIMMING,
  Q7_ANSWER_MIX,
  Q7_ANSWER_SUNBATHING,
  Q8_ANSWER_TOURISTS,
  Q8_ANSWER_LOCALS,
  Q8_ANSWER_NOBODY,
  Q6_ANSWER_TOURISTIC
} from '../consts/answers';

export const Q1_NAME = 'surface';
export const Q2_NAME = 'location';
export const Q3_NAME = 'transport';
export const Q4_NAME = 'nudity';
export const Q5_NAME = 'access';
export const Q6_NAME = 'wild';
export const Q7_NAME = 'activity';
export const Q8_NAME = 'needs';

const questions = [
  {
    id: 1,
    name: Q1_NAME,
    question: 'Piaszczysta czy kamienista?',
    options: [Q1_ANSWER_SAND, Q1_ANSWER_ROCKY],
    type: TYPE_OBLIGATORY,
  },
  {
    id: 2,
    name: Q2_NAME,
    question: 'W której części wyspy?',
    options: [Q2_ANSWER_NORTH, Q2_ANSWER_EAST, Q2_ANSWER_SOUTH, Q2_ANSWER_WEST],
    type: TYPE_OBLIGATORY,
  },
  {
    id: 3,
    name: Q3_NAME,
    question: 'Czym planujesz jechać?',
    options: [Q3_ANSWER_BUS, Q3_ANSWER_CAR],
    type: TYPE_OBLIGATORY,
  },
  {
    id: 4,
    name: Q4_NAME,
    question: 'Plaża dla nudystów?',
    options: [Q4_ANSWER_NUDITY, Q4_ANSWER_BIKINI],
    type: TYPE_OBLIGATORY_REVERSED,
  },
  // {
  //   id: 5,
  //   name: Q5_NAME,
  //   question: 'Jaki dostęp do plaży?',
  //   options: [Q5_ANSWER_EASY, Q5_ANSWER_CHALLENGING, Q5_ANSWER_TREKKING],
  //   type: TYPE_OBLIGATORY_REVERSED,
  // },
  {
    id: 6,
    name: Q6_NAME,
    question: 'Jaki klimat lubisz?',
    options: [Q6_ANSWER_TOURISTIC, Q6_ANSWER_WILD, Q6_ANSWER_NORMAL],
    type: TYPE_OPTIONAL,
  },
  {
    id: 7,
    name: Q7_NAME,
    question: 'Jak chcesz spędzić czas?',
    options: [Q7_ANSWER_SUNBATHING, Q7_ANSWER_MIX, Q7_ANSWER_SWIMMING],
    type: TYPE_OPTIONAL,
  },
  {
    id: 8,
    name: Q8_NAME,
    question: 'Jakim typem jesteś?',
    options: [Q8_ANSWER_TOURISTS, Q8_ANSWER_LOCALS, Q8_ANSWER_NOBODY],
    type: TYPE_OPTIONAL,
  },
];

export default questions;
