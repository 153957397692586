import {
  Q1_ANSWER_SAND,
  Q1_ANSWER_ROCKY,
  Q2_ANSWER_NORTH,
  Q2_ANSWER_EAST,
  Q2_ANSWER_SOUTH,
  Q2_ANSWER_WEST,
  Q3_ANSWER_CAR,
  Q3_ANSWER_BUS,
  Q4_ANSWER_NUDITY,
  Q4_ANSWER_BIKINI,
  Q5_ANSWER_EASY,
  Q5_ANSWER_CHALLENGING,
  Q5_ANSWER_TREKKING,
  Q6_ANSWER_WILD,
  Q6_ANSWER_NORMAL,
  Q7_ANSWER_SUNBATHING,
  Q7_ANSWER_MIX,
  Q7_ANSWER_SWIMMING,
  Q8_ANSWER_TOURISTS, Q8_ANSWER_LOCALS, Q8_ANSWER_NOBODY, Q6_ANSWER_TOURISTIC,
} from '../consts/answers';
import { TYPE_EXPLORER, TYPE_LOCAL, TYPE_SUNBATHER } from '../data/beaches';

const VALUE_100 = 1;
const VALUE_75 = 0.75;
const VALUE_50 = 0.5;
const VALUE_25 = 0.25;
const VALUE_0 = 0;

const BASE_WAGE = 10;
const BASE_DIVIDER = 1;
const DOUBLE_DIVIDER = 2;
const DOUBLE_MULTIPLIER = 2;

/**
 * Surface
 */
export const handleQ1 = (question, answer, beachesScore) => {
  const filteredSurfaceBeaches = beachesScore.filter(beach => {
    switch (answer) {
      case Q1_ANSWER_SAND:
        return beach.attributes.sandy;
      case Q1_ANSWER_ROCKY:
        return beach.attributes.rocky;
      default:
        return true;
    }
  });

  return filteredSurfaceBeaches.map(beach => {
    switch (answer) {
      case Q1_ANSWER_SAND:
      case Q1_ANSWER_ROCKY:
        const beachScore = beach.attributes.sandy && beach.attributes.rocky
          ? beach.score - 5 : beach.score;

        return {
          ...beach,
          score: beachScore,
        }
      default:
        return beach;
    }
  });
};

/**
 * Location
 */
export const handleQ2 = (question, answer, beachesScore) => {
  return beachesScore.filter(beach => {
    switch (answer) {
      case Q2_ANSWER_NORTH:
        return beach.attributes.north;
      case Q2_ANSWER_EAST:
        return beach.attributes.east;
      case Q2_ANSWER_SOUTH:
        return beach.attributes.south;
      case Q2_ANSWER_WEST:
        return beach.attributes.west;
      default:
        return true;
    }
  });
};

/**
 * Transport
 */
export const handleQ3 = (question, answer, beachesScore) => {
  return beachesScore.filter(beach => {
    switch (answer) {
      case Q3_ANSWER_BUS:
        return beach.attributes.bus;
      case Q3_ANSWER_CAR:
      default:
        return true;
    }
  });
};

/**
 * Nudity
 */
export const handleQ4 = (question, answer, beachesScore) => {
  return beachesScore.filter(beach => {
    switch (answer) {
      case Q4_ANSWER_NUDITY:
        return beach.attributes.nudist;
      case Q4_ANSWER_BIKINI:
        return !beach.attributes.nudist;
      default:
        return true;
    }
  });
};

/**
 * Accessibility
 */
export const handleQ5 = (question, answer, beachesScore) => {
  return beachesScore.filter(beach => {
    switch (answer) {
      case Q5_ANSWER_EASY:
        return beach.attributes.accessible;
      case Q5_ANSWER_CHALLENGING:
        return !beach.attributes.accessible && !beach.attributes.trekking;
      case Q5_ANSWER_TREKKING:
        return beach.attributes.trekking;
      default:
        return true;
    }
  });
};

/**
 * Wildness
 */
export const handleQ6 = (question, answer, beachesScore) => {
  return beachesScore.map(beach => {
    switch (answer) {
      case Q6_ANSWER_WILD:
        const wildBeachScore = beach.score
          + (beach.attributes.wild >= VALUE_75
            ? ((BASE_WAGE * beach.attributes.wild) / BASE_DIVIDER)
            : ((BASE_WAGE * beach.attributes.wild) / DOUBLE_DIVIDER)
          );

        return {
          ...beach,
          score: wildBeachScore,
        }
      case Q6_ANSWER_NORMAL:
        const normalBeachScore = beach.score
          + (beach.attributes.wild >= VALUE_25 && beach.attributes.wild <= VALUE_50
              ? ((BASE_WAGE * beach.attributes.wild) * DOUBLE_MULTIPLIER)
              : 0
          );

        return {
          ...beach,
          score: normalBeachScore,
        }
      case Q6_ANSWER_TOURISTIC:
        const touristicBeachScore = beach.score
          + (beach.attributes.wild <= VALUE_25
              ? (BASE_WAGE * (VALUE_100 - beach.attributes.wild))
              : (((BASE_WAGE * (VALUE_100 - beach.attributes.wild))) / DOUBLE_DIVIDER)
          );

        return {
          ...beach,
          score: touristicBeachScore,
        }
      default:
        return beach;
    }
  });
};

/**
 * Activity
 */
export const handleQ7 = (question, answer, beachesScore) => {
  switch (answer) {
    case Q7_ANSWER_SWIMMING:
      let filteredSwimmingBeaches = beachesScore.filter(beach => (
        beach.attributes.swimming >= 0.50
      ));

      return filteredSwimmingBeaches.map(beach => {
        return {
          ...beach,
          score: beach.score
            + ((BASE_WAGE * beach.attributes.swimming) / BASE_DIVIDER)
            + (beach.attributes.lifeguard ? 2 : 0)
            + (beach.attributes.showers ? 1 : 0)
        }
      })
    case Q7_ANSWER_MIX:
      let filteredMixBeaches = beachesScore.filter(beach => (
        beach.attributes.swimming >= 0.25
      ));

      return filteredMixBeaches.map(beach => (
        {
          ...beach,
          score: beach.score
            + ((BASE_WAGE * beach.attributes.swimming) / DOUBLE_DIVIDER)
            + (beach.attributes.lifeguard ? 1 : 0)
            + (beach.attributes.showers ? 1 : 0)
        }
      ))
    case Q7_ANSWER_SUNBATHING:
    default:
      return beachesScore;
  }
};

/**
 * Needs
 */
export const handleQ8 = (question, answer, beachesScore) => {
  return beachesScore.map(beach => {
    switch (answer) {
      case Q8_ANSWER_TOURISTS:
        return {
          ...beach,
          score: beach.score
            + ((beach.type === TYPE_SUNBATHER) ? 5 : 0),
        }
      case Q8_ANSWER_LOCALS:
        return {
          ...beach,
          score: beach.score
            + ((beach.type === TYPE_LOCAL) ? 5 : 0),
        }
      case Q8_ANSWER_NOBODY:
        return {
          ...beach,
          score: beach.score
            + ((beach.type === TYPE_EXPLORER) ? 5 : 0),
        }
      default:
        return beach;
    }
  });
};