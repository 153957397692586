export const WHATEVER = 'whatever';

export const Q1_ANSWER_SAND = 'piaszczysta';
export const Q1_ANSWER_ROCKY = 'kamienista';

export const Q2_ANSWER_NORTH = 'północ';
export const Q2_ANSWER_EAST = 'wschód';
export const Q2_ANSWER_SOUTH = 'południe';
export const Q2_ANSWER_WEST = 'zachód';

export const Q3_ANSWER_BUS = 'autobusem';
export const Q3_ANSWER_CAR = 'samochodem';

export const Q4_ANSWER_NUDITY = 'tak';
export const Q4_ANSWER_BIKINI = 'nie';

export const Q5_ANSWER_EASY = 'musi być łatwy';
export const Q5_ANSWER_CHALLENGING = 'może być nieco utrudniony';
export const Q5_ANSWER_TREKKING = 'wolę długi spacer';

export const Q6_ANSWER_WILD = 'dziki';
export const Q6_ANSWER_NORMAL = 'coś pomiędzy';
export const Q6_ANSWER_TOURISTIC = 'turystyczny';

export const Q7_ANSWER_SUNBATHING = 'opalając się';
export const Q7_ANSWER_SWIMMING = 'pływając';
export const Q7_ANSWER_MIX = 'opalając się z przerwami na schłodzenie w wodzie';

export const Q8_ANSWER_TOURISTS = 'plażowicz';
export const Q8_ANSWER_LOCALS = 'lokalny eksplorator';
export const Q8_ANSWER_NOBODY = 'podróżnik poza szlakiem';
