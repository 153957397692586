import React, { useState, useEffect } from 'react';
import bcrypt from 'bcryptjs';
import beaches from './data/beaches';
import questions from './data/questions';
import { HASHED_PASSWORD } from './consts/pass';
import { Q1_NAME, Q2_NAME, Q3_NAME, Q4_NAME, Q5_NAME, Q6_NAME, Q7_NAME, Q8_NAME } from './data/questions';
import { handleQ1, handleQ2, handleQ3, handleQ4, handleQ5, handleQ6, handleQ7, handleQ8 } from './services/questionHandler';
import PasswordForm, { KEY } from './components/PasswordForm';
import Result from './components/Result';
import Rank from './components/Rank';
import Indicators from './components/IndicatorPanel/Indicators';
import Footer from './components/Footer';
import Quiz from './components/Quiz';
import Loader from './components/Loader';

const App = () => {
  const [currentQuestion, setCurrentQuestion] = useState(-1);
  const [answers, setAnswers] = useState([]);
  const [beachesScore, setBeachesScore] = useState([]);
  const [password, setPassword] = useState('');
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    reset();
  }, []);

  const reset = () => {
    setCurrentQuestion(-1);
    setAnswers([]);
    setBeachesScore(beaches);
    setLoaded(true);
  }

  const handleAnswer = (answer) => {
    const currentQuestionId = questions[currentQuestion].id;
    const question = questions[currentQuestion];

    setAnswers([...answers, { questionId: currentQuestionId, answer }]);
    setCurrentQuestion(currentQuestion + 1);

    let modifiedScore = null;

    switch (question.name) {
      case Q1_NAME:
        modifiedScore = handleQ1(question, answer, beachesScore);
        break;
      case Q2_NAME:
        modifiedScore = handleQ2(question, answer, beachesScore);
        break;
      case Q3_NAME:
        modifiedScore = handleQ3(question, answer, beachesScore);
        break;
      case Q4_NAME:
        modifiedScore = handleQ4(question, answer, beachesScore);
        break;
      // case Q5_NAME:
      //   modifiedScore = handleQ5(question, answer, beachesScore);
      //   break;
      case Q6_NAME:
        modifiedScore = handleQ6(question, answer, beachesScore);
        break;
      case Q7_NAME:
        modifiedScore = handleQ7(question, answer, beachesScore);
        break;
      case Q8_NAME:
        modifiedScore = handleQ8(question, answer, beachesScore);
        break;
      default:
        modifiedScore = beachesScore;
    }

    setBeachesScore(modifiedScore);

    // if (modifiedScore.length === 1) {
    //   setCurrentQuestion(questions.length);
    // }
  };

  const isAuthenticated = () => {
    const pass = localStorage.getItem(KEY);

    const isAuth = pass && bcrypt.compareSync(pass, HASHED_PASSWORD);
    if (isAuth && currentQuestion < 0) {
      setCurrentQuestion(0);
    }

    return currentQuestion > -1;
  };

  return (
    <>
      {!isAuthenticated() ? (
        <PasswordForm
          password={password}
          setPassword={setPassword}
          setCurrentQuestion={setCurrentQuestion}
        />
      ) : (
        currentQuestion >= 0 && currentQuestion < questions.length && beachesScore.length > 0 ? (
          <>
            <Indicators currentQuestion={currentQuestion} questions={questions} />
            <Quiz
              currentQuestion={currentQuestion}
              handleAnswer={handleAnswer}
            />
          </>
        ) : (
          <>
            {!loaded && <Loader />}
            {loaded && (
              <Result
                beachesScore={beachesScore}
                reset={reset}
              />
            )}
          </>
        )
      )}
      <Footer />
    </>
  );
};

export default App;
