import React from 'react';
import Copyright from './Copyright';
import Box from '@mui/material/Box';

const Footer = () => (
  <Box
    component="footer"
    sx={{
      py: 2,
      pb: 2,
      pt: 5,
      mt: 'auto',
      position: 'sticky'
    }}
  >
    <Copyright />
  </Box>
);

export default Footer;
