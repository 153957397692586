import QuestionContainer from './Question/QuestionContainer';
import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';

const Quiz = ({ currentQuestion, handleAnswer}) => (
  <div className="container">
    <div className="multisteps_form position-relative" id="wizard">
      <CssBaseline />
        <QuestionContainer
          currentQuestion={currentQuestion}
          handleAnswer={handleAnswer}
        />
    </div>
  </div>
);

export default Quiz;
