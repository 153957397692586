import React from 'react';
import Indicator from './Indicator';

const Indicators = ({ currentQuestion, questions }) => {
  return (
    <div className="container-fluid pt-5">
      <div className="row text-center">
        <div className="col step_progress d-flex d-sm-block">
          {questions.map((question, index) => (
            <Indicator key={index} active={currentQuestion < index} />
          ))}
        </div>
      </div>
    </div>
  )
};

export default Indicators;
