import RefreshIcon from '@mui/icons-material/Refresh';
import React from 'react';

const ResetButton = ({ reset }) => {
  return (
    <div className="d-flex flex-column align-items-center">
      <label
        onClick={() => reset()}
        className="show-more"
      >
        <RefreshIcon /> Od nowa
      </label>
    </div>
  );
};

export default ResetButton;
