import React, { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ResetButton from './ResetButton';

const Result = ({ beachesScore, reset }) => {
  const [showMore, setShowMore] = useState(false);

  return (
    <div className="container mb-4">
      {beachesScore.length > 0 ? (
        <>
          <span className="rank-beach-header question_number text-uppercase d-flex justify-content-center align-items-center">
            Twoje TOP {beachesScore.length >= 3 ? 3 : beachesScore.length}
          </span>
          {beachesScore.sort((a, b) => b.score - a.score).slice(0, 3).map((beach, index) => (
            <div className="result-container d-flex flex-row align-items-center">
              <div className="d-flex align-items-center">
                <span className="result-number">{index + 1}.&nbsp;</span>
                <span className="result-name">{beach.name}</span>
              </div>
            </div>
          ))}
          {!showMore && beachesScore.length <= 3 &&
            <ResetButton reset={reset} />
          }
          <div style={{ marginTop: '30px'}}>
            <div className="d-flex flex-column align-items-center">
              {!showMore && beachesScore.length > 3 && <label
                onClick={() => setShowMore(true)}
                className="show-more"
              >
                <ExpandMoreIcon /> Pokaż więcej
              </label>}
              {!showMore && beachesScore.length > 3 && <ResetButton reset={reset} />}
            </div>
            {showMore && <>
              {beachesScore.sort((a, b) => b.score - a.score).slice(3, 10).map((beach, index) => (
                <div className="result-container-minor d-flex flex-row align-items-center">
                  <div className="d-flex align-items-center">
                    <span className="result-number-minor">{index + 4}.&nbsp;</span>
                    <span className="result-name-minor">{beach.name}</span>
                  </div>
                </div>
              ))}
              <ResetButton reset={reset} />
            </>}
          </div>
        </>
      ) : (
        <>
          <p className="d-flex justify-content-center align-items-center no-result-header-top">
            Upss...
          </p>
          <p className="d-flex justify-content-center align-items-center no-result-header">
            Chyba udało Ci się zagiąć kanaryjską rzeczywistość.
          </p>
          <div className="d-flex justify-content-center align-items-center">
            <p className="no-result-content">
              Nie znaleźliśmy żadnej plaży idealnie dopasowanej do Twoich potrzeb. Pamiętaj, że w niektórych miejscach wyspy może być ciężko znaleźć np. publiczną plażę pozwalającą na opalanie się nago, lub dziką do której dojeżdża autobus i da się przy niej pływać.
            </p>
          </div>
          <ResetButton reset={reset} />
        </>
      )}
    </div>
  );
};

export default Result;
