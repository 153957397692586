import Skeleton from '@mui/material/Skeleton';
import React from 'react';

const Loader = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Skeleton
        variant="rounded"
        sx={{
          bgcolor: '#ffc533',
          marginTop: '60px'
        }}
        width="90%"
        height="15px"
      />
      <Skeleton
        variant="rounded"
        sx={{
          bgcolor: '#ffc533',
          marginTop: '90px'
        }}
        width="140px"
      />
      <Skeleton
        variant="rounded"
        sx={{
          bgcolor: '#ffc533',
          marginTop: '40px'
        }}
        width="70%"
        height="60px"
      />
      <Skeleton
        variant="rounded"
        sx={{
          bgcolor: '#ffc533',
          marginTop: '45px'
        }}
        width="300px"
        height="65px"
      />
      <Skeleton
        variant="rounded"
        sx={{
          bgcolor: '#ffc533',
          marginTop: '20px'
        }}
        width="300px"
        height="65px"
      />
      <Skeleton
        variant="rounded"
        sx={{
          bgcolor: '#ffc533',
          marginTop: '20px'
        }}
        width="300px"
        height="65px"
      />
    </div>
  );
}

export default Loader;
