import React from 'react';
import questions from '../../data/questions';
import { WHATEVER } from '../../consts/answers';

const Question = ({ currentQuestion, handleAnswer }) => {
  const question = questions[currentQuestion];

  if (question) {
    return (
      <>
        <span className="span question_number text-uppercase d-flex justify-content-center align-items-center">
          Pytanie {currentQuestion + 1}/{questions.length}
        </span>
        <h1 className="question_title text-center">{question.question}</h1>
        <div className="form_items d-flex justify-content-center">
          <div className="ps-0">
            {question.options.map((option, index) => (
              <label
                key={index}
                onClick={() => handleAnswer(option)}
                className="answer bg-white"
              >
                {option}
              </label>
            ))}
            <label
              onClick={() => handleAnswer(WHATEVER)}
              className="answer answer-whatever"
            >
              obojętne
            </label>
          </div>
        </div>
      </>
    );
  }
};

export default Question;
