export const TYPE_SUNBATHER = 'sunbather';
export const TYPE_LOCAL = 'local';
export const TYPE_EXPLORER = 'explorer';

const beaches = [
  {
    id: 1,
    name: 'Las Canteras',
    type: TYPE_SUNBATHER,
    score: 5,
    attributes: {
      sandy: true,
      rocky: false,
      sunrise: false,
      sunset: true,
      remote: false,
      bus: true,
      large: true,
      accessible: true,
      nudist: false,
      toilets: true,
      showers: true,
      lifeguard: true,
      wild: 0,
      swimming: 0.75,
      north: true,
      east: true,
      south: false,
      west: false,
      trekking: false,
    }
  },
  {
    id: 2,
    name: 'Playa del Confital',
    type: TYPE_LOCAL,
    score: 3,
    attributes: {
      sandy: true,
      rocky: true,
      sunrise: false,
      sunset: true,
      remote: true,
      bus: true,
      large: true,
      accessible: true,
      nudist: true,
      toilets: true,
      showers: false,
      lifeguard: true,
      wild: 0.75,
      swimming: 0.25,
      north: true,
      east: true,
      south: false,
      west: false,
      trekking: false,
    }
  },
  {
    id: 3,
    name: 'Playa de las Alcaravaneras',
    type: TYPE_LOCAL,
    score: 3,
    attributes: {
      sandy: true,
      rocky: false,
      sunrise: true,
      sunset: false,
      remote: false,
      bus: true,
      large: true,
      accessible: true,
      nudist: false,
      toilets: true,
      showers: true,
      lifeguard: true,
      wild: 0,
      swimming: 0.75,
      north: true,
      east: true,
      south: false,
      west: false,
      trekking: false,
    }
  },
  {
    id: 4,
    name: 'Playa de La Laja',
    type: TYPE_LOCAL,
    score: 4,
    attributes: {
      sandy: true,
      rocky: false,
      sunrise: true,
      sunset: false,
      remote: true,
      bus: true,
      large: true,
      accessible: true,
      nudist: false,
      toilets: true,
      showers: false,
      lifeguard: true,
      wild: 0.5,
      swimming: 0.5,
      north: false,
      east: true,
      south: false,
      west: false,
      trekking: false,
    }
  },
  {
    id: 5,
    name: 'Playa Chica de La Estrella',
    type: TYPE_LOCAL,
    score: 1,
    attributes: {
      sandy: true,
      rocky: false,
      sunrise: true,
      sunset: false,
      remote: false,
      bus: true,
      large: false,
      accessible: true,
      nudist: false,
      toilets: false,
      showers: false,
      lifeguard: false,
      wild: 0.25,
      swimming: 0.25,
      north: false,
      east: true,
      south: false,
      west: false,
      trekking: false,
    }
  },
  {
    id: 6,
    name: 'Playa de San Borondón',
    type: TYPE_LOCAL,
    score: 1,
    attributes: {
      sandy: true,
      rocky: false,
      sunrise: true,
      sunset: false,
      remote: false,
      bus: true,
      large: false,
      accessible: true,
      nudist: false,
      toilets: false,
      showers: false,
      lifeguard: false,
      wild: 0.50,
      swimming: 0.25,
      north: false,
      east: true,
      south: false,
      west: false,
      trekking: false,
    }
  },
  {
    id: 7,
    name: 'Playa de Palos',
    type: TYPE_LOCAL,
    score: 1,
    attributes: {
      sandy: true,
      rocky: false,
      sunrise: true,
      sunset: false,
      remote: false,
      bus: true,
      large: false,
      accessible: true,
      nudist: false,
      toilets: false,
      showers: false,
      lifeguard: false,
      wild: 0.25,
      swimming: 0.25,
      north: false,
      east: true,
      south: false,
      west: false,
      trekking: false,
    }
  },
  {
    id: 8,
    name: 'Playa de La Garita',
    type: TYPE_LOCAL,
    score: 3,
    attributes: {
      sandy: true,
      rocky: false,
      sunrise: true,
      sunset: false,
      remote: false,
      bus: true,
      large: false,
      accessible: true,
      nudist: false,
      toilets: true,
      showers: true,
      lifeguard: true,
      wild: 0,
      swimming: 0.5,
      north: false,
      east: true,
      south: false,
      west: false,
      trekking: false,
    }
  },
  {
    id: 9,
    name: 'Playa Hoya del Pozo',
    type: TYPE_LOCAL,
    score: 2,
    attributes: {
      sandy: true,
      rocky: false,
      sunrise: true,
      sunset: false,
      remote: false,
      bus: true,
      large: false,
      accessible: true,
      nudist: false,
      toilets: true,
      showers: true,
      lifeguard: true,
      wild: 0,
      swimming: 0.50,
      north: false,
      east: true,
      south: false,
      west: false,
      trekking: false,
    }
  },
  {
    id: 10,
    name: 'Playa del Hombre',
    type: TYPE_LOCAL,
    score: 3,
    attributes: {
      sandy: true,
      rocky: false,
      sunrise: true,
      sunset: false,
      remote: false,
      bus: true,
      large: true,
      accessible: true,
      nudist: false,
      toilets: false,
      showers: true,
      lifeguard: false,
      wild: 0.25,
      swimming: 0.25,
      north: false,
      east: true,
      south: false,
      west: false,
      trekking: false,
    }
  },
  {
    id: 11,
    name: 'Playa de Punta del Castellano – Taliarte',
    type: TYPE_LOCAL,
    score: 0,
    attributes: {
      sandy: false,
      rocky: true,
      sunrise: false,
      sunset: false,
      remote: false,
      bus: true,
      large: false,
      accessible: true,
      nudist: false,
      toilets: false,
      showers: false,
      lifeguard: false,
      wild: 0.25,
      swimming: 0.25,
      north: false,
      east: true,
      south: false,
      west: false,
      trekking: false,
    }
  },
  {
    id: 12,
    name: 'Charca de Taliarte',
    type: TYPE_LOCAL,
    score: 0,
    attributes: {
      sandy: false,
      rocky: true,
      sunrise: false,
      sunset: false,
      remote: false,
      bus: true,
      large: false,
      accessible: true,
      nudist: false,
      toilets: false,
      showers: false,
      lifeguard: false,
      wild: 0.25,
      swimming: 0.50,
      north: false,
      east: true,
      south: false,
      west: false,
      trekking: false,
    }
  },
  {
    id: 13,
    name: 'Playa de Melenara',
    type: TYPE_SUNBATHER,
    score: 4,
    attributes: {
      sandy: true,
      rocky: false,
      sunrise: false,
      sunset: false,
      remote: false,
      bus: true,
      large: true,
      accessible: true,
      nudist: false,
      toilets: true,
      showers: true,
      lifeguard: true,
      wild: 0,
      swimming: 0.75,
      north: false,
      east: true,
      south: false,
      west: false,
      trekking: false,
    }
  },
  {
    id: 14,
    name: 'Playa de Salinetas',
    type: TYPE_LOCAL,
    score: 3,
    attributes: {
      sandy: true,
      rocky: false,
      sunrise: true,
      sunset: false,
      remote: false,
      bus: true,
      large: true,
      accessible: true,
      nudist: false,
      toilets: true,
      showers: true,
      lifeguard: true,
      wild: 0,
      swimming: 0.75,
      north: false,
      east: true,
      south: false,
      west: false,
      trekking: false,
    }
  },
  {
    id: 15,
    name: 'Playa de Silva',
    type: TYPE_LOCAL,
    score: 0,
    attributes: {
      sandy: true,
      rocky: true,
      sunrise: false,
      sunset: false,
      remote: true,
      bus: false,
      large: false,
      accessible: false,
      nudist: false,
      toilets: false,
      showers: false,
      lifeguard: false,
      wild: 0.5,
      swimming: 0.25,
      north: false,
      east: true,
      south: false,
      west: false,
      trekking: false,
    }
  },
  {
    id: 16,
    name: 'Playa de Aguadulce',
    type: TYPE_EXPLORER,
    score: 3,
    attributes: {
      sandy: true,
      rocky: false,
      sunrise: false,
      sunset: false,
      remote: true,
      bus: false,
      large: false,
      accessible: false,
      nudist: true,
      toilets: false,
      showers: false,
      lifeguard: false,
      wild: 0.75,
      swimming: 0.50,
      north: false,
      east: true,
      south: false,
      west: false,
      trekking: true,
    }
  },
  {
    id: 17,
    name: 'Playa de Tufia',
    type: TYPE_LOCAL,
    score: 2,
    attributes: {
      sandy: true,
      rocky: false,
      sunrise: false,
      sunset: false,
      remote: false,
      bus: false,
      large: false,
      accessible: true,
      nudist: false,
      toilets: false,
      showers: true,
      lifeguard: false,
      wild: 0.25,
      swimming: 0.75,
      north: false,
      east: true,
      south: false,
      west: false,
      trekking: false,
    }
  },
  {
    id: 18,
    name: 'Playa de Ojos de Garza',
    type: TYPE_LOCAL,
    score: 3,
    attributes: {
      sandy: true,
      rocky: false,
      sunrise: true,
      sunset: false,
      remote: false,
      bus: true,
      large: false,
      accessible: true,
      nudist: false,
      toilets: false,
      showers: true,
      lifeguard: true,
      wild: 0,
      swimming: 0.5,
      north: false,
      east: true,
      south: false,
      west: false,
      trekking: false,
    }
  },
  {
    id: 19,
    name: 'Playa de San Agustín de Ingenio',
    type: TYPE_EXPLORER,
    score: 2,
    attributes: {
      sandy: true,
      rocky: false,
      sunrise: true,
      sunset: false,
      remote: true,
      bus: true,
      large: false,
      accessible: false,
      nudist: false,
      toilets: false,
      showers: false,
      lifeguard: false,
      wild: 1,
      swimming: 0.25,
      north: false,
      east: true,
      south: false,
      west: false,
      trekking: true,
    }
  },
  {
    id: 20,
    name: 'Playa del Burrero',
    type: TYPE_LOCAL,
    score: 2,
    attributes: {
      sandy: true,
      rocky: true,
      sunrise: true,
      sunset: false,
      remote: false,
      bus: true,
      large: true,
      accessible: true,
      nudist: false,
      toilets: true,
      showers: true,
      lifeguard: true,
      wild: 0,
      swimming: 0.5,
      north: false,
      east: true,
      south: false,
      west: false,
      trekking: false,
    }
  },
  {
    id: 21,
    name: 'Playa Vargas',
    type: TYPE_LOCAL,
    score: 1,
    attributes: {
      sandy: false,
      rocky: true,
      sunrise: true,
      sunset: false,
      remote: true,
      bus: false,
      large: true,
      accessible: true,
      nudist: false,
      toilets: false,
      showers: false,
      lifeguard: false,
      wild: 0.5,
      swimming: 0.25,
      north: false,
      east: true,
      south: false,
      west: false,
      trekking: false,
    }
  },
  {
    id: 22,
    name: 'Playa Muelle Viejo',
    type: TYPE_EXPLORER,
    score: 0,
    attributes: {
      sandy: true,
      rocky: true,
      sunrise: true,
      sunset: false,
      remote: false,
      bus: true,
      large: false,
      accessible: true,
      nudist: false,
      toilets: false,
      showers: false,
      lifeguard: false,
      wild: 0.5,
      swimming: 0.25,
      north: false,
      east: true,
      south: false,
      west: false,
      trekking: false,
    }
  },
  {
    id: 23,
    name: 'Playa de Risco Verde',
    type: TYPE_LOCAL,
    score: 0,
    attributes: {
      sandy: false,
      rocky: true,
      sunrise: false,
      sunset: false,
      remote: false,
      bus: true,
      large: false,
      accessible: true,
      nudist: false,
      toilets: false,
      showers: false,
      lifeguard: false,
      wild: 0,
      swimming: 0.25,
      north: false,
      east: true,
      south: false,
      west: false,
      trekking: false,
    }
  },
  {
    id: 24,
    name: 'Playa de Arinaga',
    type: TYPE_LOCAL,
    score: 4,
    attributes: {
      sandy: true,
      rocky: true,
      sunrise: false,
      sunset: false,
      remote: false,
      bus: true,
      large: true,
      accessible: true,
      nudist: false,
      toilets: true,
      showers: true,
      lifeguard: true,
      wild: 0,
      swimming: 1,
      north: false,
      east: true,
      south: false,
      west: false,
      trekking: false,
    }
  },
  {
    id: 25,
    name: 'Playa de Cueva Laya',
    type: TYPE_EXPLORER,
    score: 1,
    attributes: {
      sandy: true,
      rocky: true,
      sunrise: false,
      sunset: false,
      remote: true,
      bus: false,
      large: true,
      accessible: true,
      nudist: true,
      toilets: false,
      showers: false,
      lifeguard: false,
      wild: 1,
      swimming: 0.25,
      north: false,
      east: true,
      south: false,
      west: false,
      trekking: false,
    }
  },
  {
    id: 26,
    name: 'Playa de la Gaviota',
    type: TYPE_EXPLORER,
    score: 0,
    attributes: {
      sandy: false,
      rocky: true,
      sunrise: false,
      sunset: false,
      remote: true,
      bus: false,
      large: true,
      accessible: false,
      nudist: true,
      toilets: false,
      showers: false,
      lifeguard: false,
      wild: 1,
      swimming: 0.25,
      north: false,
      east: true,
      south: false,
      west: false,
      trekking: true,
    }
  },
  {
    id: 27,
    name: 'Playa de Pozo Izquierdo',
    type: TYPE_LOCAL,
    score: 2,
    attributes: {
      sandy: false,
      rocky: true,
      sunrise: true,
      sunset: false,
      remote: false,
      bus: false,
      large: true,
      accessible: true,
      nudist: false,
      toilets: true,
      showers: true,
      lifeguard: true,
      wild: 0.25,
      swimming: 0.5,
      north: false,
      east: true,
      south: false,
      west: false,
      trekking: false,
    }
  },
  {
    id: 28,
    name: 'Playa del Cardón',
    type: TYPE_EXPLORER,
    score: 1,
    attributes: {
      sandy: false,
      rocky: true,
      sunrise: true,
      sunset: false,
      remote: true,
      bus: true,
      large: true,
      accessible: false,
      nudist: true,
      toilets: false,
      showers: false,
      lifeguard: false,
      wild: 1,
      swimming: 0.25,
      north: false,
      east: true,
      south: true,
      west: false,
      trekking: true,
    }
  },
  {
    id: 29,
    name: 'Playa de Tarajalillo',
    type: TYPE_EXPLORER,
    score: 0,
    attributes: {
      sandy: true,
      rocky: true,
      sunrise: false,
      sunset: false,
      remote: false,
      bus: true,
      large: false,
      accessible: false,
      nudist: false,
      toilets: false,
      showers: false,
      lifeguard: false,
      wild: 0.5,
      swimming: 0.25,
      north: false,
      east: false,
      south: true,
      west: false,
      trekking: true,
    }
  },
  // {
  //   id: 30,
  //   name: 'Playa de Tarajalillo',
  //   type: TYPE_EXPLORER,
  //   score: 0,
  //   attributes: {
  //     sandy: true,
  //     rocky: true,
  //     sunrise: false,
  //     sunset: false,
  //     remote: false,
  //     bus: true,
  //     large: false,
  //     accessible: false,
  //     nudist: false,
  //     toilets: false,
  //     showers: false,
  //     lifeguard: false,
  //     wild: 0.5,
  //     swimming: 0.25,
  //     north: false,
  //     east: false,
  //     south: true,
  //     west: false,
  //     trekking: true,
  //   }
  // },
  {
    id: 31,
    name: 'Playa Bahía Feliz',
    type: TYPE_SUNBATHER,
    score: 1,
    attributes: {
      sandy: true,
      rocky: true,
      sunrise: false,
      sunset: false,
      remote: false,
      bus: true,
      large: true,
      accessible: true,
      nudist: false,
      toilets: false,
      showers: false,
      lifeguard: false,
      wild: 0,
      swimming: 0.5,
      north: false,
      east: false,
      south: true,
      west: false,
      trekking: false,
    }
  },
  {
    id: 32,
    name: 'Playa del Águila',
    type: TYPE_SUNBATHER,
    score: 2,
    attributes: {
      sandy: true,
      rocky: true,
      sunrise: false,
      sunset: false,
      remote: false,
      bus: true,
      large: true,
      accessible: true,
      nudist: false,
      toilets: false,
      showers: true,
      lifeguard: false,
      wild: 0,
      swimming: 0.5,
      north: false,
      east: false,
      south: true,
      west: false,
      trekking: false,
    }
  },
  {
    id: 33,
    name: 'Playa del Pirata',
    type: TYPE_LOCAL,
    score: 3,
    attributes: {
      sandy: true,
      rocky: true,
      sunrise: true,
      sunset: false,
      remote: false,
      bus: true,
      large: false,
      accessible: false,
      nudist: false,
      toilets: false,
      showers: false,
      lifeguard: false,
      wild: 0.5,
      swimming: 0.5,
      north: false,
      east: false,
      south: true,
      west: false,
      trekking: false,
    }
  },
  {
    id: 34,
    name: 'Playa de San Agustín',
    type: TYPE_SUNBATHER,
    score: 4,
    attributes: {
      sandy: true,
      rocky: false,
      sunrise: false,
      sunset: false,
      remote: false,
      bus: true,
      large: true,
      accessible: true,
      nudist: false,
      toilets: true,
      showers: true,
      lifeguard: true,
      wild: 0,
      swimming: 0.75,
      north: false,
      east: false,
      south: true,
      west: false,
      trekking: false,
    }
  },
  {
    id: 35,
    name: 'Playa de las Burras',
    type: TYPE_SUNBATHER,
    score: 3,
    attributes: {
      sandy: true,
      rocky: true,
      sunrise: false,
      sunset: false,
      remote: false,
      bus: true,
      large: true,
      accessible: true,
      nudist: false,
      toilets: false,
      showers: true,
      lifeguard: true,
      wild: 0,
      swimming: 0.75,
      north: false,
      east: false,
      south: true,
      west: false,
      trekking: false,
    }
  },
  {
    id: 36,
    name: 'Playa del Veril',
    type: TYPE_SUNBATHER,
    score: 3,
    attributes: {
      sandy: true,
      rocky: false,
      sunrise: true,
      sunset: false,
      remote: false,
      bus: true,
      large: true,
      accessible: true,
      nudist: false,
      toilets: true,
      showers: true,
      lifeguard: false,
      wild: 0.25,
      swimming: 0.5,
      north: false,
      east: false,
      south: true,
      west: false,
      trekking: false,
    }
  },
  {
    id: 37,
    name: 'Playa del Inglés',
    type: TYPE_SUNBATHER,
    score: 4,
    attributes: {
      sandy: true,
      rocky: false,
      sunrise: true,
      sunset: false,
      remote: false,
      bus: true,
      large: true,
      accessible: true,
      nudist: true,
      toilets: true,
      showers: true,
      lifeguard: true,
      wild: 0.25,
      swimming: 0.5,
      north: false,
      east: false,
      south: true,
      west: false,
      trekking: false,
    }
  },
  {
    id: 38,
    name: 'Playa de Maspalomas',
    type: TYPE_SUNBATHER,
    score: 4,
    attributes: {
      sandy: true,
      rocky: false,
      sunrise: false,
      sunset: false,
      remote: false,
      bus: true,
      large: true,
      accessible: true,
      nudist: true,
      toilets: true,
      showers: true,
      lifeguard: true,
      wild: 0.25,
      swimming: 0.5,
      north: false,
      east: false,
      south: true,
      west: false,
      trekking: false,
    }
  },
  {
    id: 39,
    name: 'Playa del Faro',
    type: TYPE_SUNBATHER,
    score: 0,
    attributes: {
      sandy: true,
      rocky: true,
      sunrise: false,
      sunset: false,
      remote: false,
      bus: true,
      large: true,
      accessible: true,
      nudist: false,
      toilets: true,
      showers: true,
      lifeguard: false,
      wild: 0,
      swimming: 0.25,
      north: false,
      east: false,
      south: true,
      west: false,
      trekking: false,
    }
  },
  {
    id: 40,
    name: 'Playa de las Mujeres',
    type: TYPE_LOCAL,
    score: 0,
    attributes: {
      sandy: false,
      rocky: true,
      sunrise: false,
      sunset: true,
      remote: false,
      bus: false,
      large: false,
      accessible: false,
      nudist: false,
      toilets: false,
      showers: false,
      lifeguard: false,
      wild: 0.25,
      swimming: 0.25,
      north: false,
      east: false,
      south: true,
      west: false,
      trekking: false,
    }
  },
  {
    id: 41,
    name: 'Playa de las Meloneras',
    type: TYPE_SUNBATHER,
    score: 4,
    attributes: {
      sandy: true,
      rocky: true,
      sunrise: false,
      sunset: true,
      remote: false,
      bus: true,
      large: true,
      accessible: true,
      nudist: false,
      toilets: true,
      showers: true,
      lifeguard: true,
      wild: 0.25,
      swimming: 0.75,
      north: false,
      east: false,
      south: true,
      west: false,
      trekking: false,
    }
  },
  {
    id: 42,
    name: 'Playa de Pasito Blanco',
    type: TYPE_LOCAL,
    score: 5,
    attributes: {
      sandy: true,
      rocky: false,
      sunrise: false,
      sunset: false,
      remote: false,
      bus: true,
      large: false,
      accessible: true,
      nudist: false,
      toilets: false,
      showers: false,
      lifeguard: false,
      wild: 0.25,
      swimming: 0.75,
      north: false,
      east: false,
      south: true,
      west: false,
      trekking: false,
    }
  },
  {
    id: 43,
    name: 'Playa de Pasito Bea',
    type: TYPE_EXPLORER,
    score: 3,
    attributes: {
      sandy: true,
      rocky: false,
      sunrise: false,
      sunset: false,
      remote: true,
      bus: true,
      large: false,
      accessible: false,
      nudist: false,
      toilets: false,
      showers: false,
      lifeguard: false,
      wild: 1,
      swimming: 0.5,
      north: false,
      east: false,
      south: true,
      west: false,
      trekking: true,
    }
  },
  {
    id: 44,
    name: 'Playa de Montaña La Arena',
    type: TYPE_EXPLORER,
    score: 5,
    attributes: {
      sandy: true,
      rocky: false,
      sunrise: false,
      sunset: false,
      remote: true,
      bus: true,
      large: false,
      accessible: false,
      nudist: true,
      toilets: false,
      showers: false,
      lifeguard: false,
      wild: 1,
      swimming: 0.5,
      north: false,
      east: false,
      south: true,
      west: false,
      trekking: true,
    }
  },
  {
    id: 45,
    name: 'Playa de las Carpinteras',
    type: TYPE_EXPLORER,
    score: 1,
    attributes: {
      sandy: false,
      rocky: true,
      sunrise: false,
      sunset: false,
      remote: true,
      bus: true,
      large: false,
      accessible: false,
      nudist: false,
      toilets: false,
      showers: false,
      lifeguard: false,
      wild: 1,
      swimming: 0.25,
      north: false,
      east: false,
      south: true,
      west: false,
      trekking: false,
    }
  },
  {
    id: 46,
    name: 'Playa de Triana',
    type: TYPE_EXPLORER,
    score: 1,
    attributes: {
      sandy: true,
      rocky: true,
      sunrise: false,
      sunset: false,
      remote: true,
      bus: true,
      large: true,
      accessible: false,
      nudist: true,
      toilets: false,
      showers: false,
      lifeguard: false,
      wild: 0.75,
      swimming: 0.25,
      north: false,
      east: false,
      south: true,
      west: false,
      trekking: false,
    }
  },
  {
    id: 47,
    name: 'Playa de los Bigotes',
    type: TYPE_EXPLORER,
    score: 1,
    attributes: {
      sandy: false,
      rocky: true,
      sunrise: false,
      sunset: false,
      remote: true,
      bus: true,
      large: false,
      accessible: false,
      nudist: true,
      toilets: false,
      showers: false,
      lifeguard: false,
      wild: 0.75,
      swimming: 0.25,
      north: false,
      east: false,
      south: true,
      west: false,
      trekking: false,
    }
  },
  {
    id: 48,
    name: 'Playa Llano de los Militares',
    type: TYPE_EXPLORER,
    score: 2,
    attributes: {
      sandy: true,
      rocky: true,
      sunrise: false,
      sunset: false,
      remote: true,
      bus: true,
      large: false,
      accessible: false,
      nudist: true,
      toilets: false,
      showers: false,
      lifeguard: false,
      wild: 0.75,
      swimming: 0.5,
      north: false,
      east: false,
      south: true,
      west: false,
      trekking: true,
    }
  },
  {
    id: 49,
    name: 'Bahía de Santa Águeda',
    type: TYPE_LOCAL,
    score: 2,
    attributes: {
      sandy: true,
      rocky: false,
      sunrise: false,
      sunset: false,
      remote: false,
      bus: true,
      large: false,
      accessible: true,
      nudist: false,
      toilets: false,
      showers: true,
      lifeguard: false,
      wild: 0,
      swimming: 0.75,
      north: false,
      east: false,
      south: true,
      west: false,
      trekking: false,
    }
  },
  {
    id: 50,
    name: 'Playa del Perchel',
    type: TYPE_SUNBATHER,
    score: 3,
    attributes: {
      sandy: true,
      rocky: false,
      sunrise: false,
      sunset: false,
      remote: false,
      bus: true,
      large: true,
      accessible: true,
      nudist: false,
      toilets: true,
      showers: true,
      lifeguard: true,
      wild: 0,
      swimming: 0.75,
      north: false,
      east: false,
      south: true,
      west: false,
      trekking: false,
    }
  },
  {
    id: 51,
    name: 'Playa las Marañuelas',
    type: TYPE_SUNBATHER,
    score: 4,
    attributes: {
      sandy: true,
      rocky: false,
      sunrise: false,
      sunset: true,
      remote: false,
      bus: true,
      large: true,
      accessible: true,
      nudist: false,
      toilets: true,
      showers: true,
      lifeguard: true,
      wild: 0,
      swimming: 1,
      north: false,
      east: false,
      south: true,
      west: false,
      trekking: false,
    }
  },
  {
    id: 52,
    name: 'Playa de Costa Alegre',
    type: TYPE_SUNBATHER,
    score: 2,
    attributes: {
      sandy: true,
      rocky: false,
      sunrise: false,
      sunset: true,
      remote: false,
      bus: true,
      large: false,
      accessible: true,
      nudist: false,
      toilets: true,
      showers: true,
      lifeguard: false,
      wild: 0,
      swimming: 1,
      north: false,
      east: false,
      south: true,
      west: false,
      trekking: false,
    }
  },
  {
    id: 53,
    name: 'Playa de Patalavaca',
    type: TYPE_SUNBATHER,
    score: 4,
    attributes: {
      sandy: true,
      rocky: false,
      sunrise: false,
      sunset: true,
      remote: false,
      bus: true,
      large: true,
      accessible: true,
      nudist: false,
      toilets: true,
      showers: true,
      lifeguard: true,
      wild: 0,
      swimming: 0.75,
      north: false,
      east: false,
      south: true,
      west: false,
      trekking: false,
    }
  },
  {
    id: 54,
    name: 'Playa Aquamarina',
    type: TYPE_SUNBATHER,
    score: 2,
    attributes: {
      sandy: true,
      rocky: false,
      sunrise: false,
      sunset: false,
      remote: false,
      bus: true,
      large: false,
      accessible: true,
      nudist: false,
      toilets: false,
      showers: false,
      lifeguard: false,
      wild: 0,
      swimming: 1,
      north: false,
      east: false,
      south: true,
      west: false,
      trekking: false,
    }
  },
  {
    id: 55,
    name: 'Playa de Anfi',
    type: TYPE_SUNBATHER,
    score: 5,
    attributes: {
      sandy: true,
      rocky: false,
      sunrise: false,
      sunset: false,
      remote: false,
      bus: true,
      large: false,
      accessible: true,
      nudist: false,
      toilets: true,
      showers: true,
      lifeguard: true,
      wild: 0,
      swimming: 1,
      north: false,
      east: false,
      south: true,
      west: false,
      trekking: false,
    }
  },
  {
    id: 56,
    name: 'Playa de Puerto Rico',
    type: TYPE_SUNBATHER,
    score: 4,
    attributes: {
      sandy: true,
      rocky: false,
      sunrise: false,
      sunset: false,
      remote: false,
      bus: true,
      large: true,
      accessible: true,
      nudist: false,
      toilets: true,
      showers: true,
      lifeguard: true,
      wild: 0,
      swimming: 1,
      north: false,
      east: false,
      south: true,
      west: false,
      trekking: false,
    }
  },
  {
    id: 57,
    name: 'Playa de Amadores',
    type: TYPE_SUNBATHER,
    score: 5,
    attributes: {
      sandy: true,
      rocky: false,
      sunrise: false,
      sunset: true,
      remote: false,
      bus: true,
      large: true,
      accessible: true,
      nudist: false,
      toilets: true,
      showers: true,
      lifeguard: true,
      wild: 0,
      swimming: 1,
      north: false,
      east: false,
      south: true,
      west: false,
      trekking: false,
    }
  },
  {
    id: 58,
    name: 'Playa de Tauro',
    type: TYPE_LOCAL,
    score: 4,
    attributes: {
      sandy: true,
      rocky: false,
      sunrise: false,
      sunset: true,
      remote: true,
      bus: true,
      large: true,
      accessible: true,
      nudist: false,
      toilets: false,
      showers: false,
      lifeguard: false,
      wild: 0.5,
      swimming: 0.5,
      north: false,
      east: false,
      south: true,
      west: false,
      trekking: false,
    }
  },
  {
    id: 59,
    name: 'Playa del Cura',
    type: TYPE_SUNBATHER,
    score: 3,
    attributes: {
      sandy: true,
      rocky: false,
      sunrise: false,
      sunset: true,
      remote: false,
      bus: true,
      large: true,
      accessible: true,
      nudist: false,
      toilets: false,
      showers: true,
      lifeguard: true,
      wild: 0,
      swimming: 0.75,
      north: false,
      east: false,
      south: true,
      west: false,
      trekking: false,
    }
  },
  {
    id: 60,
    name: 'Playa de Los Frailes',
    type: TYPE_EXPLORER,
    score: 2,
    attributes: {
      sandy: false,
      rocky: true,
      sunrise: false,
      sunset: false,
      remote: true,
      bus: true,
      large: false,
      accessible: true,
      nudist: true,
      toilets: false,
      showers: false,
      lifeguard: false,
      wild: 1,
      swimming: 0.25,
      north: false,
      east: false,
      south: true,
      west: false,
      trekking: false,
    }
  },
  {
    id: 61,
    name: 'Playa de Medio Almud',
    type: TYPE_EXPLORER,
    score: 3,
    attributes: {
      sandy: true,
      rocky: true,
      sunrise: false,
      sunset: false,
      remote: true,
      bus: true,
      large: false,
      accessible: false,
      nudist: true,
      toilets: false,
      showers: false,
      lifeguard: false,
      wild: 1,
      swimming: 0.25,
      north: false,
      east: false,
      south: true,
      west: false,
      trekking: true,
    }
  },
  {
    id: 62,
    name: 'Playa de Mogán',
    type: TYPE_SUNBATHER,
    score: 5,
    attributes: {
      sandy: true,
      rocky: false,
      sunrise: false,
      sunset: false,
      remote: false,
      bus: true,
      large: false,
      accessible: true,
      nudist: false,
      toilets: true,
      showers: true,
      lifeguard: true,
      wild: 0,
      swimming: 1,
      north: false,
      east: false,
      south: true,
      west: true,
      trekking: true,
    }
  },
  {
    id: 63,
    name: 'Playa de Güi Güi',
    type: TYPE_EXPLORER,
    score: 5,
    attributes: {
      sandy: true,
      rocky: false,
      sunrise: false,
      sunset: true,
      remote: true,
      bus: false,
      large: true,
      accessible: false,
      nudist: true,
      toilets: false,
      showers: false,
      lifeguard: false,
      wild: 1,
      swimming: 0.5,
      north: false,
      east: false,
      south: false,
      west: true,
      trekking: true,
    }
  },
  {
    id: 64,
    name: 'Playa de La Aldea',
    type: TYPE_LOCAL,
    score: 2,
    attributes: {
      sandy: false,
      rocky: true,
      sunrise: false,
      sunset: true,
      remote: false,
      bus: true,
      large: true,
      accessible: true,
      nudist: false,
      toilets: true,
      showers: true,
      lifeguard: false,
      wild: 0.25,
      swimming: 0.25,
      north: false,
      east: false,
      south: false,
      west: true,
      trekking: false,
    }
  },
  {
    id: 65,
    name: 'Playa del Puerto',
    type: TYPE_EXPLORER,
    score: 4,
    attributes: {
      sandy: true,
      rocky: false,
      sunrise: false,
      sunset: true,
      remote: true,
      bus: true,
      large: false,
      accessible: false,
      nudist: true,
      toilets: false,
      showers: false,
      lifeguard: false,
      wild: 1,
      swimming: 0.25,
      north: false,
      east: false,
      south: false,
      west: true,
      trekking: true,
    }
  },
  {
    id: 66,
    name: 'Playa del Risco',
    type: TYPE_EXPLORER,
    score: 3,
    attributes: {
      sandy: false,
      rocky: true,
      sunrise: false,
      sunset: false,
      remote: true,
      bus: true,
      large: true,
      accessible: false,
      nudist: true,
      toilets: false,
      showers: false,
      lifeguard: false,
      wild: 1,
      swimming: 0.25,
      north: false,
      east: false,
      south: false,
      west: true,
      trekking: false,
    }
  },
  {
    id: 67,
    name: 'Playa de Sotavento',
    type: TYPE_EXPLORER,
    score: 4,
    attributes: {
      sandy: false,
      rocky: true,
      sunrise: false,
      sunset: true,
      remote: true,
      bus: true,
      large: false,
      accessible: false,
      nudist: true,
      toilets: false,
      showers: false,
      lifeguard: false,
      wild: 1,
      swimming: 0.25,
      north: false,
      east: false,
      south: false,
      west: true,
      trekking: true,
    }
  },
  {
    id: 68,
    name: 'Playa de Guayedra',
    type: TYPE_EXPLORER,
    score: 5,
    attributes: {
      sandy: true,
      rocky: true,
      sunrise: false,
      sunset: true,
      remote: true,
      bus: true,
      large: true,
      accessible: false,
      nudist: true,
      toilets: false,
      showers: false,
      lifeguard: false,
      wild: 1,
      swimming: 0.25,
      north: false,
      east: false,
      south: false,
      west: true,
      trekking: true,
    }
  },
  {
    id: 69,
    name: 'Playa del Muelle (Agaete)',
    type: TYPE_LOCAL,
    score: 1,
    attributes: {
      sandy: true,
      rocky: true,
      sunrise: false,
      sunset: true,
      remote: false,
      bus: true,
      large: false,
      accessible: true,
      nudist: false,
      toilets: false,
      showers: false,
      lifeguard: false,
      wild: 0.25,
      swimming: 0.5,
      north: false,
      east: false,
      south: false,
      west: true,
      trekking: false,
    }
  },
  {
    id: 70,
    name: 'Playa de Las Nieves',
    type: TYPE_LOCAL,
    score: 3,
    attributes: {
      sandy: false,
      rocky: true,
      sunrise: false,
      sunset: true,
      remote: false,
      bus: true,
      large: false,
      accessible: true,
      nudist: false,
      toilets: true,
      showers: true,
      lifeguard: true,
      wild: 0,
      swimming: 0.75,
      north: false,
      east: false,
      south: false,
      west: true,
      trekking: false,
    }
  },
  {
    id: 71,
    name: 'Playa de las Salinas',
    type: TYPE_LOCAL,
    score: 0,
    attributes: {
      sandy: true,
      rocky: true,
      sunrise: false,
      sunset: true,
      remote: false,
      bus: true,
      large: false,
      accessible: true,
      nudist: false,
      toilets: true,
      showers: false,
      lifeguard: false,
      wild: 0.25,
      swimming: 0.25,
      north: false,
      east: false,
      south: false,
      west: true,
      trekking: false,
    }
  },
  {
    id: 72,
    name: 'Playa de la Caleta',
    type: TYPE_EXPLORER,
    score: 1,
    attributes: {
      sandy: false,
      rocky: true,
      sunrise: false,
      sunset: false,
      remote: true,
      bus: true,
      large: false,
      accessible: false,
      nudist: true,
      toilets: false,
      showers: false,
      lifeguard: false,
      wild: 1,
      swimming: 0.25,
      north: false,
      east: false,
      south: false,
      west: true,
      trekking: false,
    }
  },
  {
    id: 73,
    name: 'Playa del Juncal',
    type: TYPE_EXPLORER,
    score: 4,
    attributes: {
      sandy: false,
      rocky: true,
      sunrise: false,
      sunset: false,
      remote: true,
      bus: false,
      large: false,
      accessible: false,
      nudist: true,
      toilets: false,
      showers: false,
      lifeguard: false,
      wild: 1,
      swimming: 0.5,
      north: false,
      east: false,
      south: false,
      west: true,
      trekking: true,
    }
  },
  {
    id: 74,
    name: 'Playa de Martorell',
    type: TYPE_EXPLORER,
    score: 3,
    attributes: {
      sandy: true,
      rocky: true,
      sunrise: false,
      sunset: false,
      remote: true,
      bus: false,
      large: false,
      accessible: true,
      nudist: false,
      toilets: false,
      showers: false,
      lifeguard: false,
      wild: 0.75,
      swimming: 0.5,
      north: true,
      east: false,
      south: false,
      west: true,
      trekking: false,
    }
  },
  {
    id: 75,
    name: 'Playa de Sardina',
    type: TYPE_LOCAL,
    score: 5,
    attributes: {
      sandy: true,
      rocky: false,
      sunrise: false,
      sunset: true,
      remote: false,
      bus: false,
      large: false,
      accessible: true,
      nudist: false,
      toilets: true,
      showers: true,
      lifeguard: true,
      wild: 0,
      swimming: 0.5,
      north: true,
      east: false,
      south: false,
      west: true,
      trekking: false,
    }
  },
  {
    id: 76,
    name: 'Playa del Muelle (Sardina)',
    type: TYPE_LOCAL,
    score: 4,
    attributes: {
      sandy: true,
      rocky: true,
      sunrise: false,
      sunset: true,
      remote: false,
      bus: false,
      large: false,
      accessible: true,
      nudist: false,
      toilets: false,
      showers: false,
      lifeguard: false,
      wild: 0.25,
      swimming: 0.5,
      north: true,
      east: false,
      south: false,
      west: true,
      trekking: false,
    }
  },
  {
    id: 77,
    name: 'Playa El Roquete',
    type: TYPE_EXPLORER,
    score: 3,
    attributes: {
      sandy: false,
      rocky: true,
      sunrise: false,
      sunset: true,
      remote: true,
      bus: false,
      large: false,
      accessible: true,
      nudist: false,
      toilets: false,
      showers: false,
      lifeguard: false,
      wild: 0.5,
      swimming: 0.25,
      north: true,
      east: false,
      south: false,
      west: true,
      trekking: false,
    }
  },
  {
    id: 78,
    name: 'Playa del Barranquillo el Vino',
    type: TYPE_EXPLORER,
    score: 1,
    attributes: {
      sandy: false,
      rocky: true,
      sunrise: false,
      sunset: false,
      remote: true,
      bus: false,
      large: false,
      accessible: false,
      nudist: false,
      toilets: false,
      showers: false,
      lifeguard: false,
      wild: 1,
      swimming: 0.5,
      north: true,
      east: false,
      south: false,
      west: false,
      trekking: false,
    }
  },
  {
    id: 79,
    name: 'Playa de los Dos Roques',
    type: TYPE_LOCAL,
    score: 2,
    attributes: {
      sandy: true,
      rocky: false,
      sunrise: false,
      sunset: false,
      remote: false,
      bus: false,
      large: false,
      accessible: true,
      nudist: false,
      toilets: true,
      showers: false,
      lifeguard: false,
      wild: 0.25,
      swimming: 0.75,
      north: true,
      east: false,
      south: false,
      west: false,
      trekking: false,
    }
  },
  {
    id: 80,
    name: 'Playa Boca Barranco',
    type: TYPE_LOCAL,
    score: 4,
    attributes: {
      sandy: true,
      rocky: false,
      sunrise: false,
      sunset: false,
      remote: false,
      bus: false,
      large: false,
      accessible: true,
      nudist: false,
      toilets: false,
      showers: false,
      lifeguard: false,
      wild: 0.25,
      swimming: 0.25,
      north: true,
      east: false,
      south: false,
      west: false,
      trekking: false,
    }
  },
  {
    id: 81,
    name: 'Playa de Caleta de Arriba',
    type: TYPE_LOCAL,
    score: 2,
    attributes: {
      sandy: false,
      rocky: true,
      sunrise: false,
      sunset: false,
      remote: false,
      bus: false,
      large: false,
      accessible: true,
      nudist: false,
      toilets: false,
      showers: false,
      lifeguard: false,
      wild: 0.25,
      swimming: 0.5,
      north: true,
      east: false,
      south: false,
      west: false,
      trekking: false,
    }
  },
  {
    id: 82,
    name: 'Playa Las Puntillas',
    type: TYPE_EXPLORER,
    score: 0,
    attributes: {
      sandy: false,
      rocky: true,
      sunrise: false,
      sunset: false,
      remote: true,
      bus: true,
      large: false,
      accessible: false,
      nudist: true,
      toilets: false,
      showers: false,
      lifeguard: false,
      wild: 1,
      swimming: 0.25,
      north: true,
      east: false,
      south: false,
      west: false,
      trekking: true,
    }
  },
  {
    id: 83,
    name: 'Playa San Felipe',
    type: TYPE_LOCAL,
    score: 4,
    attributes: {
      sandy: true,
      rocky: true,
      sunrise: false,
      sunset: false,
      remote: false,
      bus: true,
      large: true,
      accessible: true,
      nudist: false,
      toilets: false,
      showers: false,
      lifeguard: true,
      wild: 0.5,
      swimming: 0.25,
      north: true,
      east: false,
      south: false,
      west: false,
      trekking: false,
    }
  },
  {
    id: 84,
    name: 'Playa San Andrés',
    type: TYPE_LOCAL,
    score: 1,
    attributes: {
      sandy: true,
      rocky: true,
      sunrise: false,
      sunset: false,
      remote: false,
      bus: true,
      large: true,
      accessible: true,
      nudist: false,
      toilets: false,
      showers: true,
      lifeguard: false,
      wild: 0,
      swimming: 0.25,
      north: true,
      east: false,
      south: false,
      west: false,
      trekking: false,
    }
  },
  {
    id: 85,
    name: 'Playa de Los Enanos',
    type: TYPE_EXPLORER,
    score: 0,
    attributes: {
      sandy: false,
      rocky: true,
      sunrise: false,
      sunset: false,
      remote: false,
      bus: true,
      large: true,
      accessible: true,
      nudist: false,
      toilets: false,
      showers: false,
      lifeguard: false,
      wild: 0.25,
      swimming: 0,
      north: true,
      east: false,
      south: false,
      west: false,
      trekking: false,
    }
  },
  {
    id: 86,
    name: 'Playa Las Coloradas',
    type: TYPE_EXPLORER,
    score: 0,
    attributes: {
      sandy: false,
      rocky: true,
      sunrise: false,
      sunset: false,
      remote: false,
      bus: true,
      large: false,
      accessible: true,
      nudist: false,
      toilets: false,
      showers: false,
      lifeguard: false,
      wild: 0.5,
      swimming: 0.25,
      north: true,
      east: false,
      south: false,
      west: false,
      trekking: false,
    }
  },
  {
    id: 87,
    name: 'Playa El Puertillo',
    type: TYPE_LOCAL,
    score: 3,
    attributes: {
      sandy: true,
      rocky: false,
      sunrise: false,
      sunset: true,
      remote: false,
      bus: true,
      large: false,
      accessible: true,
      nudist: false,
      toilets: false,
      showers: true,
      lifeguard: true,
      wild: 0,
      swimming: 0.75,
      north: true,
      east: false,
      south: false,
      west: false,
      trekking: false,
    }
  },
  {
    id: 88,
    name: 'Playa de Charco de Las Palomas',
    type: TYPE_EXPLORER,
    score: 1,
    attributes: {
      sandy: true,
      rocky: true,
      sunrise: false,
      sunset: true,
      remote: true,
      bus: true,
      large: false,
      accessible: true,
      nudist: false,
      toilets: false,
      showers: false,
      lifeguard: false,
      wild: 0.75,
      swimming: 0.25,
      north: true,
      east: false,
      south: false,
      west: false,
      trekking: false,
    }
  },
];

export default beaches;
