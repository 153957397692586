import React, { useState } from 'react';
import bcrypt from 'bcryptjs';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { HASHED_PASSWORD } from '../consts/pass';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const KEY = 'password';

const PasswordForm = ({ password, setPassword, setCurrentQuestion }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handlePasswordSubmit = () => {
    bcrypt.compare(password, HASHED_PASSWORD, (err, result) => {
      if (result) {
        localStorage.setItem(KEY, password);
        setCurrentQuestion(0);
      } else {
        handleClick();
      }
    });
  };

  return (
    <>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <BeachAccessIcon sx={{ color: '#fff', fontSize: '8rem', marginBottom: '2rem' }} />
        <h1 className="question_title text-center">Znajdź swoją idealną plażę</h1>
        <Box component="form" onSubmit={handlePasswordSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            type="password"
            id="password"
            label="Hasło"
            name="password"
            autoComplete="current-password"
            onChange={(e) => setPassword(e.target.value)}
            autoFocus
          />
          <label
            onClick={() => handlePasswordSubmit()}
            className="form-submit-button bg-white"
          >
            Zaloguj się
          </label>
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
              Niepoprawne hasło dostępu
            </Alert>
          </Snackbar>
        </Box>
      </Box>
    </>
  );
};

export default PasswordForm